function NativeBridgeBase(){
  this.callbacksCount = 1;
  this.callbacks = {};
  this.downloadCallback = null;
  this.resultOperation = null;
}
// Automatically called by native layer when a result is available
NativeBridgeBase.prototype.resultForCallback  = function (callbackId, resultArray) {
  try {
    var callback = this.callbacks[callbackId];
    if (!callback && this.downloadCallback) {
      callback = this.downloadCallback;
    } else if (!callback) return;
    var errorResultObj;
    if (resultArray instanceof Array && typeof resultArray[0] === 'string') {
      errorResultObj=JSON.parse(resultArray[0]);
    }else{
       errorResultObj= resultArray[0]
    }
    var errorResult=errorResultObj?.error;
    if(errorResult){
      let errorCodeType=null;
      switch (errorResult.errorCode) {
        case "ERR400":
          errorCodeType=400;
          break;
        case "ERR401":
          errorCodeType=401;
          break;
        case "ERR403":
          errorCodeType=403;
          break;
        case "ERR404":
          errorCodeType=404;
          break;
        case "ERR422":
            errorCodeType=422;
          break;  
        case "ERR500":
          errorCodeType=500;
          break;
        case "ERR502":
          errorCodeType=502;
          break;
        case "ERR503":
          errorCodeType=503;
          break;
        case "ERR504":
          errorCodeType=504;
          break;
      }
      errorResult.statusCode=errorCodeType;
      // reverted the changes for handling 500 internal server error in native apps
      window.parent.postMessage({systemError: errorResult}, window.location.origin);
    }

    if (this.resultOperation && typeof this.resultOperation === 'function') {
      this.resultOperation(resultArray);
    }

    callback.apply(null, resultArray);
  } catch (e) {
  }
};
